<template>
  <v-container class="home-case-studies-section">
    <TitleParagraphRow :value="value" />

    <v-row v-if="value.items && value.items.length" align="center">
      <v-col>
        <v-row class="d-lg-none">
          <v-col>
            <div
              v-if="value.side_text"
              class="text-caption text-uppercase mb-2"
            >
              {{ value.side_text }}
            </div>

            <v-lazy>
              <v-chip-group
                v-if="value.items.length > 2"
                v-model="selectedItem"
                mandatory
                selected-class="text-red-darken-2"
              >
                <v-chip v-for="item in value.items" :key="item.id">
                  {{ item.organization.name }}
                </v-chip>
              </v-chip-group>
            </v-lazy>
          </v-col>
        </v-row>

        <v-row>
          <SideTextCol :value="value.side_text" />

          <v-col>
            <v-lazy>
              <v-item-group v-model="selectedItem" mandatory>
                <div class="d-flex flex-column flex-md-row align-end">
                  <v-item
                    v-for="item in value.items"
                    :key="item.id"
                    v-slot="{ isSelected, toggle }"
                  >
                    <HomeCaseStudiesItem
                      :selected="isSelected"
                      :value="item"
                      @click="isSelected ? undefined : toggle()"
                    />
                  </v-item>
                </div>
              </v-item-group>
            </v-lazy>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const props = defineProps({
  value: {
    required: true,
    type: Object
  }
})

const selectedItem = ref(null)

onMounted(() => {
  if (props.value.items.length > 0)
    selectedItem.value = props.value.items.length - 1
})
</script>

<style lang="scss">
.home-case-studies-section {
  --tan: 0.158384440324536; // 9deg
  --clip-offset: calc(260px * var(--tan));
}
</style>
