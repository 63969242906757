<template>
  <v-card :href="value.href" class="fill-height d-flex flex-column" rounded="0">
    <v-card-item
      class="text-white pa-6"
      style="background: linear-gradient(180deg, #198ab3 80%, transparent 0)"
    >
      <v-row>
        <v-col
          class="text-title text-uppercase text-truncate font-weight-bold"
          >{{ value.group_name }}</v-col
        >
      </v-row>

      <v-row>
        <v-col align="center">
          <v-avatar
            v-if="imgResp.src"
            :rounded="value.image_choice === 'Person' ? 0 : undefined"
            :style="{ borderColor: theme.current.value.colors.surface }"
            size="175"
            style="border-style: solid; border-width: 11px"
          >
            <v-img
              :aspect-ratio="aspectRatio"
              :sizes="imgResp.sizes"
              :src="imgResp.src"
              :srcset="imgResp.srcset"
              alt="Image related to promoted offer"
            />
          </v-avatar>
        </v-col>
      </v-row>
    </v-card-item>

    <v-card-item class="text-h5 font-weight-medium">
      <StyledText :value="value.title"
    /></v-card-item>

    <v-card-item
      v-if="value.persons_heading && value.persons && value.persons.length"
      class="text-body-1"
    >
      <span class="text-capitalize font-weight-bold"
        >{{ value.persons_heading }}:</span
      >
      {{ value.persons.map(person => person.name).join(', ') }}
    </v-card-item>

    <v-card-item v-if="value.about_content">
      <div v-if="value.about_heading" class="text-capitalize font-weight-bold">
        {{ value.about_heading }}:
      </div>

      <ContentBlock
        :value="value.about_content"
        :list-style="{ listStyleType: 'square' }"
        em-class="text-decoration-underline"
      />
    </v-card-item>

    <v-card-item v-if="whenStr" class="text-body-1 pb-6">
      <span class="font-weight-bold">When:</span>
      {{ whenStr }}
    </v-card-item>

    <v-card-actions class="mt-auto pb-6">
      <v-btn
        :append-icon="mdiArrowRight"
        class="text-capitalize mx-auto"
        elevation="1"
        exact
        rounded="pill"
        >Learn More</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { computed, toRef } from 'vue'
import { useTheme } from 'vuetify'
import { DateTime } from 'luxon'
import { useImgResponsive } from '#root/lib/img'
import { mdiArrowRight } from '@mdi/js'

const props = defineProps({
  value: {
    required: true,
    type: Object
  }
})

const aspectRatio = 1
const imgResp = useImgResponsive(
  toRef(() => ({
    aspectRatio,
    image:
      props.value.image_choice === 'Offering' && props.value.image
        ? props.value.image
        : props.value.image_choice === 'Person' &&
            props.value.persons &&
            props.value.persons.length &&
            props.value.persons[0].image
          ? props.value.persons[0].image
          : undefined,
    sizes: {
      xs: '350px'
    }
  }))
)
const theme = useTheme()
const startDateTime = computed(() => {
  return (
    props.value.start &&
    DateTime.fromISO(props.value.start.local, {
      zone: props.value.start.timezone
    })
  )
})
const endDateTime = computed(() => {
  return (
    props.value.end &&
    DateTime.fromISO(props.value.end.local, {
      zone: props.value.end.timezone
    })
  )
})
const whenStr = computed(() => {
  let str = ''

  if (startDateTime.value && endDateTime.value) {
    const startDate = startDateTime.value.toLocaleString(
      DateTime.DATE_MED_WITH_WEEKDAY
    )
    const startTime = startDateTime.value.toLocaleString(DateTime.TIME_SIMPLE)
    const endDate = endDateTime.value.toLocaleString(
      DateTime.DATE_MED_WITH_WEEKDAY
    )
    const endTime = endDateTime.value.toLocaleString(DateTime.TIME_SIMPLE)
    const display = props.value.date_display

    if (display === 'Datetime' || display === 'Date')
      str = startDate === endDate ? startDate : `${startDate} – ${endDate}`

    if (display === 'Datetime')
      str = `${str} | ${startTime} – ${endTime} (${endDateTime.value.offsetNameShort})`
  }

  return str
})
</script>
