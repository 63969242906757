<template>
  <v-sheet v-once class="bg-light-blue-darken-2 py-12">
    <v-container>
      <v-row>
        <v-col align-self="center" cols="12" lg="8">
          <v-row>
            <v-col align-self="center" class="mr-lg-8">
              <v-row v-if="value.title || value.paragraph">
                <v-col>
                  <TitleParagraphBlock
                    em-class-title="text-decoration-underline"
                    :value="value"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col align="center">
                  <v-btn
                    :append-icon="mdiArrowRight"
                    class="text-none"
                    exact
                    href="/resources"
                    rounded="pill"
                    size="large"
                    variant="outlined"
                    >View All Resources</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>

            <v-col align-self="center" cols="12" lg="6">
              <v-row>
                <v-col v-for="item in value.items" :key="item.id">
                  <v-card
                    v-if="item.resource"
                    :href="resourceHref(item)"
                    class="fill-height"
                    color="transparent"
                    variant="flat"
                  >
                    <v-card-item>
                      <ImageCover
                        v-if="isArticle(item)"
                        :value="item.resource"
                        class="rounded-xl"
                        default-key="article_default_image"
                      />

                      <ImageCover
                        v-else-if="isDownload(item)"
                        :value="item.resource"
                        class="rounded-xl"
                        default-key="download_default_image"
                      />

                      <VideoCover
                        v-else-if="isVideo(item)"
                        :value="item.resource"
                        class="rounded-xl"
                        content-class="d-flex align-center justify-center"
                      >
                        <v-icon :icon="mdiPlayCircleOutline" size="50" />
                      </VideoCover>
                    </v-card-item>

                    <v-card-text v-if="item.resource.title" class="text-body-1">
                      {{ item.resource.title }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <SideTextCol :opacity="0.4" :value="value.side_text" flip />
          </v-row>
        </v-col>

        <v-col
          v-if="imgResp.src"
          class="d-none d-lg-block my-lg-n16"
          cols="12"
          lg="4"
        >
          <v-img
            :aspect-ratio="aspectRatio"
            :sizes="imgResp.sizes"
            :src="imgResp.src"
            :srcset="imgResp.srcset"
            alt="Photo for resources"
            cover
            height="100%"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script setup>
import { toRef } from 'vue'
import { useImgResponsive } from '#root/lib/img'
import { isArticle, isDownload, isVideo, resourceHref } from '#root/lib/utils'
import { mdiArrowRight, mdiPlayCircleOutline } from '@mdi/js'

const props = defineProps({
  value: {
    required: true,
    type: Object
  }
})

const aspectRatio = 1
const imgResp = useImgResponsive(
  toRef(() => ({
    aspectRatio,
    image: props.value.image,
    sizes: {
      xs: '800px'
    }
  }))
)
</script>
