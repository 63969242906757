<template>
  <v-card
    :class="selected ? 'd-flex' : 'd-none d-lg-flex flex-shrink-0'"
    :height="selected ? 560 : 260"
    :max-width="selected ? undefined : 130"
    :variant="selected ? undefined : 'flat'"
    class="ma-2"
    color="bg-surface"
    rounded="0"
  >
    <v-img
      v-show="mdAndUp"
      :aspect-ratio="aspectRatio"
      :class="selected ? '' : 'home-case-studies-img-grayscale'"
      :gradient="selected ? undefined : HOME_CASE_STUDIES_GRADIENT"
      :sizes="imgResp.sizes"
      :src="imgResp.src"
      :srcset="imgResp.srcset"
      :width="selected ? 260 : 130"
      alt="Testimonial photo for this case study"
      cover
    >
      <div class="d-flex justify-center h-100">
        <div
          v-if="
            selected &&
            value.image_choice === 'Testimonial' &&
            value.testimonial &&
            value.testimonial.image
          "
          class="align-self-end w-100 home-case-studies-clip-path"
        >
          <div class="pa-6">
            <div
              v-if="value.testimonial.designation"
              class="text-h5 text-white font-weight-light"
            >
              {{ value.testimonial.designation }}
            </div>
            <div
              v-if="value.organization && value.organization.name"
              class="text-h5 text-white font-weight-medium"
            >
              {{ value.organization.name }}
            </div>
          </div>
        </div>

        <div
          v-if="
            !selected && value.organization && value.organization.logo_white
          "
          class="d-flex align-end align-self-end w-100 mx-3 mb-6"
          style="height: 70px"
        >
          <v-img
            :aspect-ratio="
              value.organization.logo_white.width /
              value.organization.logo_white.height
            "
            :src="logoSrc(value.organization.logo_white.image)"
            alt="Logo for case study organization"
          />
        </div>
      </div>
    </v-img>

    <v-slide-y-transition>
      <div v-if="selected" class="d-flex flex-column pa-8 pa-lg-12 fill-height">
        <CaseStudyContentBlock :value="value" />
      </div>
    </v-slide-y-transition>
  </v-card>
</template>

<script setup>
import { toRef } from 'vue'
import { useDisplay } from 'vuetify'
import { HOME_CASE_STUDIES_GRADIENT } from '#root/lib/consts'
import { createImgSrc, useImgResponsive } from '#root/lib/img'

const props = defineProps({
  selected: {
    default: false,
    type: Boolean
  },
  value: {
    required: true,
    type: Object
  }
})

const { mdAndUp } = useDisplay()
const aspectRatio = 2 / 4
const imgResp = useImgResponsive(
  toRef(() => ({
    aspectRatio,
    image:
      (props.value.image_choice === 'Testimonial' &&
        props.value.testimonial &&
        props.value.testimonial.image) ||
      props.value.image
        ? props.value.image
        : undefined
  }))
)
const logoSrc = createImgSrc()
</script>

<style lang="scss">
.home-case-studies-img-grayscale img {
  filter: grayscale(1);
  z-index: -1;
}

.home-case-studies-clip-path {
  background: linear-gradient(
    180deg,
    rgba(87, 154, 164, 0.84) 0%,
    rgba(33, 72, 119, 0.84) 86.64%
  );
  padding-top: calc(var(--clip-offset));

  clip-path: polygon(0% var(--clip-offset), 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0% var(--clip-offset), 100% 0, 100% 100%, 0 100%);
}
</style>
