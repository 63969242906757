<template>
  <v-container>
    <TitleParagraphRow :value="value" />

    <v-row>
      <v-col>
        <v-lazy transition="fade-transition">
          <PromotedOfferingList :value="data" />
        </v-lazy>
      </v-col>
    </v-row>

    <v-row class="pt-6">
      <v-col align="center">
        <v-btn
          :append-icon="mdiArrowRight"
          color="secondary"
          href="/training/schedule"
          variant="text"
        >
          View Full Training Schedule
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { contentProvider } from '#root/renderer/contentProvider'
import { useIntervalFn } from '@vueuse/core'
import { mdiArrowRight } from '@mdi/js'

const FETCH_INTERVAL = 20 * 60 * 1000 // 20m
const UPDATE_INTERVAL = 60 * 1000 // 60s

defineProps({
  value: {
    required: true,
    type: Object
  }
})

const currentTime = ref(new Date().getTime())
const fetchTime = ref(0)
const data = ref(null)

async function fetchData() {
  data.value = await contentProvider.findMany('promoted-offerings', true)
  fetchTime.value = new Date().getTime()
}
async function refetchData() {
  data.value = await contentProvider.findMany('promoted-offerings', true)
  fetchTime.value = new Date().getTime()
}

useIntervalFn(async () => {
  currentTime.value = new Date().getTime()
  if (
    fetchTime.value > 0 &&
    currentTime.value - fetchTime.value > FETCH_INTERVAL
  ) {
    fetchTime.value = 0
    await refetchData()
  }
}, UPDATE_INTERVAL)

onMounted(async () => {
  await fetchData()
})
</script>
