<template>
  <div>
    <v-row v-if="isMounted && !value">
      <v-col align="center">
        <v-progress-circular
          :size="80"
          color="red-darken-2"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row v-if="value && !value.length">
      <v-col>
        <v-alert color="info" type="info">
          We’re sorry, there are no offerings at this time. Please check back
          later.
        </v-alert>
      </v-col>
    </v-row>

    <template v-if="value && value.length">
      <v-row justify="space-around">
        <v-col v-for="item in value" :key="item.id" cols="12" md="4" xl="3">
          <PromotedOfferingCard :value="item" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script setup>
import { useMounted } from '@vueuse/core'

defineProps({
  value: {
    default: undefined,
    type: Array
  }
})

// TODO: Add expired filtering!!!
const isMounted = useMounted()
</script>
